import { getFormattedAddress, Placeholders } from "@vfit/consumer/data-access";
import { checkWindow, purifyTextWithLogo } from "@vfit/shared/data-access";
export var evaluateCmsData = function(rawCmsData, address, technology, userType, isLockIn) {
    var ref, ref1;
    var droppedCartData = rawCmsData === null || rawCmsData === void 0 ? void 0 : rawCmsData.droppedcart;
    var droppedCartTitle = isLockIn ? droppedCartData === null || droppedCartData === void 0 ? void 0 : (ref = droppedCartData.lockIn) === null || ref === void 0 ? void 0 : ref.titleLockIn : droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.title;
    var droppedCartDescription = isLockIn ? droppedCartData === null || droppedCartData === void 0 ? void 0 : (ref1 = droppedCartData.lockIn) === null || ref1 === void 0 ? void 0 : ref1.descriptionLockIn : droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.description;
    var addressFromCookie = (address === null || address === void 0 ? void 0 : address.displayedAddress) === "undefined" ? "" : address === null || address === void 0 ? void 0 : address.displayedAddress;
    var formattedAddress = getFormattedAddress(addressFromCookie || "");
    var formattedTechnology = (technology === null || technology === void 0 ? void 0 : technology.displayedTecnology) || "";
    var isPanicModeEnabled = checkWindow() && window.isPanicMode;
    var ctaLabelByUserType = userType === "legacy" || isPanicModeEnabled ? droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.ctaLabelAlternative : droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.ctaLabel;
    // const titleByUserType = userType === 'next' ? droppedCartData?.titleAlternative : droppedCartData?.title
    var handlePlaceholders = function(text) {
        return purifyTextWithLogo(text.replace(Placeholders.TECHNOLOGY, formattedTechnology).replace(Placeholders.ADDRESS, formattedAddress));
    };
    var handleSpecs = function(specs) {
        return specs === null || specs === void 0 ? void 0 : specs.map(function(spec) {
            return {
                value: handlePlaceholders((spec === null || spec === void 0 ? void 0 : spec.value) || "")
            };
        });
    };
    return {
        title: droppedCartTitle || "",
        description: handlePlaceholders(droppedCartDescription || "") || "",
        specs: handleSpecs(droppedCartData === null || droppedCartData === void 0 ? void 0 : droppedCartData.specs) || [
            {
                value: ""
            }
        ],
        ctaLabel: ctaLabelByUserType || ""
    };
};
export var overrideSpecs = function(specs, globalPromoSpec) {
    var newSpecs = specs;
    if (newSpecs.length > 0 && newSpecs.length < 3) {
        newSpecs.push(globalPromoSpec);
    } else if (specs.length >= 3) {
        newSpecs[2].value = globalPromoSpec.value;
    }
    return newSpecs;
};
