import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { errorMock, useCampaignsInfo, useGenerateOTP, useGetCustomer, useGetFindCaller, useVerifyOTP } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { usePageProvider } from "@vfit/consumer/providers";
import { resetData } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
import { useCoverageToolModalProvider } from "../../coverageToolModal.context";
import { getEligibilityProduct } from "./coverageToolOtpCard.utils";
/**
 * Flow:
 * generate OTP
 * verify OTP
 * findCaller
 * getCustomer
 * campaigns
 */ export var useCoverageToolOtp = function() {
    var ref, ref1, ref2;
    var page = usePageProvider().page;
    var queryClient = useQueryClient();
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var ref3 = useCoverageToolModalProvider(), findCaller = ref3.findCaller, coverageLockInUser = ref3.coverageLockInUser;
    var ref4 = useState(""), customerId = ref4[0], setCustomerId = ref4[1];
    var ref5 = useState(false), isDelayCompensation = ref5[0], setDelayCompensation = ref5[1];
    var ref6 = useState({
        eligible: false
    }), checkEligibility = ref6[0], setCheckEligibility = ref6[1];
    var ref7 = useGenerateOTP(), generateOtpData = ref7.data, generateOtpError = ref7.error, generateOtpMutate = ref7.mutate, isErrorGenerateOtp = ref7.isError, isLoadingGenerateOtp = ref7.isLoading, isSuccessGenerateOtp = ref7.isSuccess, resetGenerateOtp = ref7.reset;
    var ref8 = useVerifyOTP(), verifyOtpMutate = ref8.mutate, verifyOtpData = ref8.data, verifyOtpError = ref8.error, isSuccessVerifyOtp = ref8.isSuccess, isErrorVerifyOtp = ref8.isError, isLoadingVerifyOtp = ref8.isLoading, resetVerifyOtp = ref8.reset;
    var ref9 = useGetFindCaller({
        fiscalCode: (coverageLockInUser === null || coverageLockInUser === void 0 ? void 0 : (ref2 = coverageLockInUser.owningIndividual) === null || ref2 === void 0 ? void 0 : ref2.fiscalCode) || ""
    }, {
        enabled: false
    }), findCallerData = ref9.data, findCallerError = ref9.error, isErrorFindCaller = ref9.isError, isLoadingFindCaller = ref9.isLoading, isSuccessFindCaller = ref9.isSuccess, refetchFindCaller = ref9.refetch;
    var ref10 = useGetCustomer(customerId, {
        enabled: false
    }), customerData = ref10.data, isLoadingCustomer = ref10.isLoading, isErrorCustomer = ref10.isError, isSuccessCustomer = ref10.isSuccess, customerError = ref10.error, refetchGetCustomer = ref10.refetch;
    var ref11 = useCampaignsInfo({
        keyDependency: [
            "campaignsLockInFromHub"
        ]
    }), campaignsData = ref11.data, campaignsError = ref11.error, isLoadingCampaigns = ref11.isLoading, isSuccessCampaigns = ref11.isSuccess, isErrorCampaigns = ref11.isError, refetchCampaigns = ref11.refetch;
    var resetCoverageToolOtpCardFlow = function() {
        resetGenerateOtp();
        resetVerifyOtp();
        resetData(queryClient, []);
    };
    useEffect(function() {
        if (isSuccessCampaigns) {
            var ref = getEligibilityProduct(allProducts, campaignsData), campaignEligibility = ref.campaignEligibility, lockInProduct = ref.lockInProduct;
            setCheckEligibility(_object_spread_props(_object_spread({}, campaignEligibility), {
                lockInProduct: lockInProduct
            }));
        }
    }, [
        isSuccessCampaigns
    ]);
    useEffect(function() {
        if (isSuccessCustomer) refetchCampaigns();
    }, [
        isSuccessCustomer
    ]);
    useEffect(function() {
        if (customerId) {
            refetchGetCustomer();
        }
    }, [
        customerId
    ]);
    useEffect(function() {
        var ref;
        if (isSuccessFindCaller && (findCallerData === null || findCallerData === void 0 ? void 0 : (ref = findCallerData.customerRef) === null || ref === void 0 ? void 0 : ref.id)) {
            setCustomerId(findCallerData.customerRef.id);
        }
    }, [
        isSuccessFindCaller,
        findCallerData
    ]);
    useEffect(function() {
        if (isSuccessVerifyOtp) {
            if (verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.id) refetchFindCaller();
        }
    }, [
        isSuccessVerifyOtp
    ]);
    var onVerifyOtp = function(otp) {
        verifyOtpMutate(otp);
    };
    var onGenerateOtp = function() {
        var ref;
        var ref1 = ((ref = findCaller) === null || ref === void 0 ? void 0 : ref.selectedContactMedium) || {}, field = ref1.field, fieldType = ref1.fieldType;
        generateOtpMutate({
            identifier: field,
            identifierType: fieldType
        });
    };
    useEffect(function() {
        onGenerateOtp();
    }, []);
    useEffect(function() {
        if (isSuccessVerifyOtp) setDelayCompensation(true);
        if (isLoadingFindCaller) setDelayCompensation(false);
    }, [
        isSuccessVerifyOtp,
        isLoadingFindCaller
    ]);
    return {
        resetCoverageToolOtpCardFlow: resetCoverageToolOtpCardFlow,
        onVerifyOtp: onVerifyOtp,
        onGenerateOtp: onGenerateOtp,
        generateOtp: _object_spread({
            isLoading: isLoadingGenerateOtp,
            isError: isErrorGenerateOtp,
            isSuccess: isSuccessGenerateOtp,
            data: generateOtpData
        }, generateOtpError && {
            error: errorMock("generateOtpError", generateOtpError)
        }),
        verifyOtp: _object_spread({
            isLoading: isLoadingVerifyOtp,
            isError: isErrorVerifyOtp,
            isSuccess: isSuccessVerifyOtp,
            data: verifyOtpData
        }, verifyOtpError && {
            error: errorMock("verifyOtp", verifyOtpError)
        }),
        findCaller: _object_spread({
            isLoading: isLoadingFindCaller || isDelayCompensation,
            isError: isErrorFindCaller,
            isSuccess: isSuccessFindCaller,
            data: findCallerData
        }, findCallerError && {
            error: errorMock("findCaller", findCallerError)
        }),
        customer: _object_spread({
            isLoading: isLoadingCustomer,
            isError: isErrorCustomer,
            isSuccess: isSuccessCustomer,
            data: customerData
        }, customerError && {
            error: errorMock("customer", customerError)
        }),
        campaigns: _object_spread({
            isLoading: isLoadingCampaigns,
            isError: isErrorCampaigns,
            isSuccess: isSuccessCampaigns,
            data: campaignsData
        }, campaignsError && {
            error: errorMock("campaigns", campaignsError)
        }),
        checkEligibility: checkEligibility
    };
};
