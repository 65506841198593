import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/codebuild/output/src3860616924/src/web-cloud-prod-vf-it/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { nextClient, LoggerInstance } from "@vfit/shared/providers";
import { errorMock, isMobileProduct } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * Recall service POST /updateProductCharacteristic
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ var updateProductCharacteristicService = function(cartId, payload, customOptions) {
    return nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.UPDATE_PRODUCT_CHARACTERISTIC), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run updateProductCharacteristicService.
 * @returns
 */ export var useUpdateProductCharacteristic = function() {
    var queryClient = useQueryClient();
    var keyString = "updateProductCharacteristic";
    return useMutation([
        "doUpdateProductCharacteristic"
    ], function(optionToReconsider) {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13;
        var payload;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var findCaller = getFromLocalStorageByKey("findCaller");
        var customerData = getFromLocalStorageByKey("customerData");
        LoggerInstance.log("UPDATE PRODUCT CHARACT GETDATAFROM STORAGE", customerData, shoppingCart);
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var owningIndividualId = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref2 = findCaller.customerRef) === null || ref2 === void 0 ? void 0 : ref2.id);
        var fixedVoiceId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref3 = shoppingCart.cartItem) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.fixedVoice) === null || ref5 === void 0 ? void 0 : ref5[0].id;
        var cartItemId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref6 = shoppingCart.cartItem) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : ref7.id;
        var ref14;
        var dataSim = !!(shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id) && ((ref14 = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref8 = shoppingCart.cartItem) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : ref9.dataSim) !== null && ref14 !== void 0 ? ref14 : [])[0];
        var dataSimId = !!dataSim && (dataSim === null || dataSim === void 0 ? void 0 : dataSim.id);
        var internetId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref10 = shoppingCart.cartItem) === null || ref10 === void 0 ? void 0 : (ref11 = ref10[0]) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.internet) === null || ref12 === void 0 ? void 0 : (ref13 = ref12[0]) === null || ref13 === void 0 ? void 0 : ref13.id;
        if (!!owningIndividualId && !!internetId) {
            payload = {
                updatedProductCharacteristicList: [
                    {
                        contactId: owningIndividualId,
                        orderActionId: internetId
                    }, 
                ],
                runRules: true
            };
            if (fixedVoiceId) {
                payload.updatedProductCharacteristicList.push({
                    contactId: owningIndividualId,
                    orderActionId: fixedVoiceId
                });
            }
            if (dataSimId && !optionToReconsider) {
                payload.updatedProductCharacteristicList.push({
                    contactId: owningIndividualId,
                    orderActionId: dataSimId
                });
            }
            if (optionToReconsider) payload.optionToReconsider = optionToReconsider.replace(/^./, optionToReconsider[0].toUpperCase());
        } else if (!!owningIndividualId && !!cartItemId && isMobileProduct()) {
            payload = {
                updatedProductCharacteristicList: [
                    {
                        contactId: owningIndividualId,
                        orderActionId: cartItemId
                    }, 
                ],
                runRules: true
            };
        }
        LoggerInstance.debug("doUpdateProductCharacteristic : ", payload);
        LoggerInstance.debug("doUpdateProductCharacteristic : ", optionToReconsider);
        localStorage.setItem("optionToReconsider", optionToReconsider === null || optionToReconsider === void 0 ? void 0 : optionToReconsider.toLowerCase());
        LoggerInstance.debug("doUpdateProductCharacteristic before calling service : ", payload);
        return updateProductCharacteristicService(cartId, payload);
    }, {
        onMutate: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(data) {
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            if (data) {
                                keyString = "updateProductCharacteristic_2";
                            }
                        case 1:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(data) {
                return _ref.apply(this, arguments);
            };
        }(),
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
export var useUPCAutomaticRecharge = function() {
    var queryClient = useQueryClient();
    var keyString = "UPCAutomaticRecharge";
    return useMutation([
        keyString
    ], function(selectedValue) {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var orderActionId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.id;
        var productId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref2 = shoppingCart.cartItem) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.product) === null || ref4 === void 0 ? void 0 : ref4.id;
        var characteristicId = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref5 = shoppingCart.cartItem) === null || ref5 === void 0 ? void 0 : (ref6 = ref5[0]) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.product) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.productCharacteristic) === null || ref8 === void 0 ? void 0 : ref8.find(function(element) {
            return element.name === "Is_Recurring_TopUp_Automatically_Included_Next_R07";
        });
        var payload = {
            updatedProductCharacteristicList: [
                {
                    productId: productId,
                    orderActionId: orderActionId,
                    productCharacteristic: {
                        id: (characteristicId === null || characteristicId === void 0 ? void 0 : characteristicId.id) || "",
                        selectedValue: selectedValue
                    }
                }, 
            ],
            runRules: true
        };
        return updateProductCharacteristicService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
