export var organizeCoverageToolCheckNumberCard = function(props) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10;
    var prefix = props === null || props === void 0 ? void 0 : (ref = props.personalinfo) === null || ref === void 0 ? void 0 : ref.lockIn;
    var modalError = {
        title: (prefix === null || prefix === void 0 ? void 0 : (ref1 = prefix.modalNewUser) === null || ref1 === void 0 ? void 0 : ref1.title) || "Non sei ancora un nostro cliente?",
        description: (prefix === null || prefix === void 0 ? void 0 : (ref2 = prefix.modalNewUser) === null || ref2 === void 0 ? void 0 : ref2.description) || "Il tuo numero non sembra essere di un nostro cliente, ma non ti preoccupare, abbiamo altre offerte pronte a stupirti!",
        editLabel: (prefix === null || prefix === void 0 ? void 0 : (ref3 = prefix.modalNewUser) === null || ref3 === void 0 ? void 0 : ref3.buttonModifyNumber) || "MODIFICA NUMERO",
        submitLabel: (prefix === null || prefix === void 0 ? void 0 : (ref4 = prefix.modalNewUser) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.action) === null || ref5 === void 0 ? void 0 : ref5.title) || "SCOPRI L'OFFERTA MIGLIORE PER TE"
    };
    var checkNumberProps = {
        title: (prefix === null || prefix === void 0 ? void 0 : (ref6 = prefix.personalInfo) === null || ref6 === void 0 ? void 0 : ref6.title) || "Ciao!",
        description: (prefix === null || prefix === void 0 ? void 0 : (ref7 = prefix.personalInfo) === null || ref7 === void 0 ? void 0 : ref7.description) || "Inserisci qui il tuo nome, cognome e numero di telefono Vodafone, per scoprire l’offerta che ti abbiamo riservato.",
        firstNameLabel: (prefix === null || prefix === void 0 ? void 0 : (ref8 = prefix.personalInfo) === null || ref8 === void 0 ? void 0 : ref8.nameLabel) || "Nome",
        lastNameLabel: (prefix === null || prefix === void 0 ? void 0 : (ref9 = prefix.personalInfo) === null || ref9 === void 0 ? void 0 : ref9.surnameLabel) || "Cognome",
        phoneNumberLabel: "Numero di telefono",
        ctaLabel: (prefix === null || prefix === void 0 ? void 0 : (ref10 = prefix.personalInfo) === null || ref10 === void 0 ? void 0 : ref10.buttonLabel) || "verifica il numero di telefono",
        goNext: "Prosegui"
    };
    return {
        modalError: modalError,
        checkNumberProps: checkNumberProps
    };
};
export var organizeNameFiscalCode = function(nameFiscalCodeCms, product) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31;
    return {
        title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref = nameFiscalCodeCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.fiscalCode) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref2 = nameFiscalCodeCms.personalinfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.fiscalCode) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        firstNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref4 = nameFiscalCodeCms.personalinfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.firstName) || "",
        lastNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref6 = nameFiscalCodeCms.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.selectInput) === null || ref7 === void 0 ? void 0 : ref7.lastName) || "",
        fiscalCodeLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref8 = nameFiscalCodeCms.personalinfo) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.selectInput) === null || ref9 === void 0 ? void 0 : ref9.fiscalCode) || "",
        buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref10 = nameFiscalCodeCms.personalinfo) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.generalInfo) === null || ref11 === void 0 ? void 0 : ref11.fcMissingLabel) || "",
        legacyPopup: {
            legacyPopupIsOpen: true,
            legacyPopupTitle: (product === null || product === void 0 ? void 0 : (ref12 = product.commonOverride) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.legacyCtc) === null || ref13 === void 0 ? void 0 : ref13.title) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref14 = nameFiscalCodeCms.personalinfo) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.clientRecognition) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.redirect) === null || ref16 === void 0 ? void 0 : ref16.title) || "",
            legacyPopupMessage: (product === null || product === void 0 ? void 0 : (ref17 = product.commonOverride) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.legacyCtc) === null || ref18 === void 0 ? void 0 : ref18.description) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref19 = nameFiscalCodeCms.personalinfo) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.clientRecognition) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.redirect) === null || ref21 === void 0 ? void 0 : ref21.description) || "",
            legacyPopupActionText: (product === null || product === void 0 ? void 0 : (ref22 = product.commonOverride) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.legacyCtc) === null || ref23 === void 0 ? void 0 : ref23.buttonLabel) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref24 = nameFiscalCodeCms.personalinfo) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.clientRecognition) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.redirect) === null || ref26 === void 0 ? void 0 : ref26.buttonLabel) || "",
            legacyPopupUrl: (product === null || product === void 0 ? void 0 : (ref27 = product.commonOverride) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.legacyCtc) === null || ref28 === void 0 ? void 0 : ref28.url) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref29 = nameFiscalCodeCms.personalinfo) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.clientRecognition) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.redirect) === null || ref31 === void 0 ? void 0 : ref31.url) || ""
        }
    };
};
export var organizeRetriveFiscalCode = function(nameFiscalCodeCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39, ref40, ref41, ref42;
    return {
        retriveFiscalCode: {
            title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref = nameFiscalCodeCms.personalinfo) === null || ref === void 0 ? void 0 : (ref1 = ref.fiscalCode) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref2 = nameFiscalCodeCms.personalinfo) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.fiscalCode) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
            firstName: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref4 = nameFiscalCodeCms.personalinfo) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.firstName) || "",
            lastName: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref6 = nameFiscalCodeCms.personalinfo) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.selectInput) === null || ref7 === void 0 ? void 0 : ref7.lastName) || "",
            birthDate: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref8 = nameFiscalCodeCms.personalinfo) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.selectInput) === null || ref9 === void 0 ? void 0 : ref9.birthdate) || "",
            gender: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref10 = nameFiscalCodeCms.personalinfo) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.selectInput) === null || ref11 === void 0 ? void 0 : ref11.gender) || "",
            nationality: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref12 = nameFiscalCodeCms.personalinfo) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.selectInput) === null || ref13 === void 0 ? void 0 : ref13.country) || "",
            birthPlace: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref14 = nameFiscalCodeCms.personalinfo) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.selectInput) === null || ref15 === void 0 ? void 0 : ref15.city) || "",
            errorMessage: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref16 = nameFiscalCodeCms.personalinfo) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.generalInfo) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.errorMessage) === null || ref18 === void 0 ? void 0 : ref18.error2) || "",
            birthNation: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref19 = nameFiscalCodeCms.personalinfo) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.selectInput) === null || ref20 === void 0 ? void 0 : ref20.birthNation) || "Nazione di nascita",
            buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref21 = nameFiscalCodeCms.personalinfo) === null || ref21 === void 0 ? void 0 : (ref22 = ref21.fiscalCode) === null || ref22 === void 0 ? void 0 : ref22.buttonLabel) || ""
        },
        nameFiscalCodeCard: {
            title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref23 = nameFiscalCodeCms.personalinfo) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.generalInfo) === null || ref24 === void 0 ? void 0 : ref24.title) || "",
            titleReadOnly: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref25 = nameFiscalCodeCms.personalinfo) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.generalInfo) === null || ref26 === void 0 ? void 0 : ref26.titleReadOnly) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref27 = nameFiscalCodeCms.personalinfo) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.generalInfo) === null || ref28 === void 0 ? void 0 : ref28.title) || "",
            description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref29 = nameFiscalCodeCms.personalinfo) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.generalInfo) === null || ref30 === void 0 ? void 0 : ref30.description) || "",
            descriptionReadOnly: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref31 = nameFiscalCodeCms.personalinfo) === null || ref31 === void 0 ? void 0 : (ref32 = ref31.generalInfo) === null || ref32 === void 0 ? void 0 : ref32.descriptionReadOnly) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref33 = nameFiscalCodeCms.personalinfo) === null || ref33 === void 0 ? void 0 : (ref34 = ref33.generalInfo) === null || ref34 === void 0 ? void 0 : ref34.description) || "",
            firstNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref35 = nameFiscalCodeCms.personalinfo) === null || ref35 === void 0 ? void 0 : (ref36 = ref35.selectInput) === null || ref36 === void 0 ? void 0 : ref36.firstName) || "",
            lastNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref37 = nameFiscalCodeCms.personalinfo) === null || ref37 === void 0 ? void 0 : (ref38 = ref37.selectInput) === null || ref38 === void 0 ? void 0 : ref38.lastName) || "",
            fiscalCodeLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref39 = nameFiscalCodeCms.personalinfo) === null || ref39 === void 0 ? void 0 : (ref40 = ref39.selectInput) === null || ref40 === void 0 ? void 0 : ref40.fiscalCode) || "",
            buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref41 = nameFiscalCodeCms.personalinfo) === null || ref41 === void 0 ? void 0 : (ref42 = ref41.generalInfo) === null || ref42 === void 0 ? void 0 : ref42.fcMissingLabel) || ""
        }
    };
};
