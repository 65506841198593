import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  color: ",
        ";\n  font-family: ",
        ";\n  font-weight: 400;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    ",
        "\n    text-align: left;\n    margin: 0 0 32px;\n\n    @media (min-width: ",
        ") {\n      margin: 0 0 44px;\n    }\n\n    @media (min-width: ",
        ") {\n      margin: 0 0 30px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        "\n  margin-bottom: 24px;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 40px;\n  }\n\n  div {\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n\n  .button-slide-container {\n    position: relative;\n    bottom: 0;\n    width: 100%;\n    background: ",
        ";\n\n    @media (min-width: ",
        ") {\n      width: 258px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 296px;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-654b5fd5-0"
})(_templateObject(), colors.$262626, fonts.regular);
export var Title = styled.div.withConfig({
    componentId: "sc-654b5fd5-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var Subtitle = styled.div.withConfig({
    componentId: "sc-654b5fd5-2"
})(_templateObject2(), pxToCssFont(18, 24), breakpoints.tablet, breakpoints.desktop);
export var Data = styled.div.withConfig({
    componentId: "sc-654b5fd5-3"
})(_templateObject3(), pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(20, 28), breakpoints.desktop, colors.$00697c);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-654b5fd5-4"
})(_templateObject4(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
