import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { getForeignCountries, getItalianMunicipalities, isValidDate } from "@vfit/shared/data-access";
var hasCFValidDate = function(cf) {
    var yearPart = cf.substring(6, 8).toUpperCase();
    var monthCode = cf.charAt(8).toUpperCase();
    var dayPart = parseInt(cf.substring(9, 11), 10);
    var months = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        H: 6,
        L: 7,
        M: 8,
        P: 9,
        R: 10,
        S: 11,
        T: 12
    };
    var year = parseInt(yearPart, 10);
    var fullYear = year < 40 ? 2000 + year : 1900 + year;
    var month = months[monthCode];
    if (!month) return false;
    if (dayPart > 40) dayPart -= 40;
    return isValidDate(dayPart, month, fullYear);
};
var hasFiscalCodeValidPlace = function(cf) {
    return _to_consumable_array(getItalianMunicipalities()).concat(_to_consumable_array(getForeignCountries())).some(function(m) {
        return m.code === cf.substring(11, 15).toUpperCase();
    });
};
export { hasCFValidDate, hasFiscalCodeValidPlace };
