import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  padding: 0 16px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 12px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  max-width: 80%;\n  justify-content: center;\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  ",
        ";\n  margin: 0;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  width: 42px;\n  height: 42px;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { pxToCssFont } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-2319b549-0"
})(_templateObject());
export var Heading = styled.div.withConfig({
    componentId: "sc-2319b549-1"
})(_templateObject1());
export var Title = styled.div.withConfig({
    componentId: "sc-2319b549-2"
})(_templateObject2(), pxToCssFont(24, 32));
export var Subtitle = styled.div.withConfig({
    componentId: "sc-2319b549-3"
})(_templateObject3(), pxToCssFont(24, 32));
export var ListContainer = styled.div.withConfig({
    componentId: "sc-2319b549-4"
})(_templateObject4());
export var ListElement = styled.div.withConfig({
    componentId: "sc-2319b549-5"
})(_templateObject5());
export var TextContainer = styled.div.withConfig({
    componentId: "sc-2319b549-6"
})(_templateObject6());
export var Text = styled.p.withConfig({
    componentId: "sc-2319b549-7"
})(_templateObject7(), pxToCssFont(20, 28));
export var IconContainer = styled.div.withConfig({
    componentId: "sc-2319b549-8"
})(_templateObject8());
