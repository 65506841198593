import { useState, useCallback } from "react";
import { debounce } from "lodash-es";
import { checkWindow, isAcceptedCookieLawGroup } from "@vfit/shared/data-access";
import { handleEnv, LoggerInstance } from "@vfit/shared/providers";
var useTrackingHeroSliderConsumer = function() {
    var ref = useState(null), prevSlide = ref[0], setPrevSlide = ref[1];
    var debouncedOnTrack = useCallback(debounce(function(s) {
        var acceptedCookie = handleEnv("NODE_ENV") === "development" ? true : isAcceptedCookieLawGroup("2");
        if (!acceptedCookie || !checkWindow()) return;
        if (!(window === null || window === void 0 ? void 0 : window["_uxa"])) window["_uxa"] = (window === null || window === void 0 ? void 0 : window["_uxa"]) || [];
        setTimeout(function() {
            try {
                var uxa = window === null || window === void 0 ? void 0 : window["_uxa"];
                LoggerInstance.log("Push uxa", {
                    category: "homepage",
                    name: "viewSlide".concat(s.id),
                    type: "scroll"
                });
                if (prevSlide !== s.id) {
                    var ref;
                    uxa === null || uxa === void 0 ? void 0 : (ref = uxa.push) === null || ref === void 0 ? void 0 : ref.call(uxa, [
                        "trackPageEvent",
                        "viewSlide".concat(s.id)
                    ]);
                    setPrevSlide(s.id);
                }
            } catch (e) {
                LoggerInstance.log("Error uxa", e);
            }
        }, 1000);
    }, 100), [
        prevSlide
    ]);
    var onTrack = function(s) {
        debouncedOnTrack(s);
    };
    return {
        onTrack: onTrack
    };
};
export { useTrackingHeroSliderConsumer };
