import { CMS_CONFIG, evaluateOfferDetails, getRedirectUrlFromHomePage, getTechByKey, retrieveCartCookie, useCmsConfig } from "@vfit/consumer/data-access";
import { API, checkWindow, isAcceptedCookieLawGroup, PAGES } from "@vfit/shared/data-access";
import { usePageProvider } from "@vfit/consumer/providers";
import { evaluateCmsData } from "./heroSliderConsumer.utils";
import { useTrackingHeroSliderConsumer } from "./heroSliderConsumer.tagging";
var useHeroSliderConsumer = function() {
    var ref, ref1, ref2;
    var page = usePageProvider().page;
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var cookieCart = retrieveCartCookie();
    var ref3 = cookieCart || {}, offerId = ref3.offerId, placeId = ref3.placeId, userType = ref3.userType, technology = ref3.technology, address = ref3.address, cmsId = ref3.cmsId, offerDetailsCookie = ref3.offerDetails;
    var basePathRedirect = (offerDetailsCookie === null || offerDetailsCookie === void 0 ? void 0 : offerDetailsCookie.redirectUrl) || "";
    var isLockinProduct = (offerDetailsCookie === null || offerDetailsCookie === void 0 ? void 0 : offerDetailsCookie.isLockinProduct) || false;
    var isAcceptedPrivacyPolicy = "production" === "development" ? true : isAcceptedCookieLawGroup("4");
    var isOkPlaceId = placeId && placeId !== "undefined";
    var displayedTechnology = technology === null || technology === void 0 ? void 0 : technology.displayedTecnology;
    var allTechs = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_TECNOLOGIES);
    var droppedCartHomepageCMS = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_DROPPED_CART_HOMEPAGE);
    var globalconfig = (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG) || {}).globalconfig;
    var panicMode = (globalconfig || {}).panicMode;
    if (checkWindow() && panicMode) {
        window.isPanicModeFixed = panicMode.enablePanicModeFixed === "true";
    }
    var cmsRawData = displayedTechnology ? getTechByKey(allTechs, displayedTechnology) : droppedCartHomepageCMS;
    var offerDetails = evaluateOfferDetails(allProducts, cookieCart === null || cookieCart === void 0 ? void 0 : cookieCart.productId);
    var cmsRefinedData = evaluateCmsData(cmsRawData, address, technology, userType, offerDetails === null || offerDetails === void 0 ? void 0 : offerDetails.price, offerDetails === null || offerDetails === void 0 ? void 0 : offerDetails.originalPrice, isLockinProduct);
    var onTrack = useTrackingHeroSliderConsumer().onTrack;
    return {
        showCookieCart: Boolean(cookieCart) && isAcceptedPrivacyPolicy && isOkPlaceId && (droppedCartHomepageCMS === null || droppedCartHomepageCMS === void 0 ? void 0 : (ref2 = droppedCartHomepageCMS.droppedcarthomepage) === null || ref2 === void 0 ? void 0 : ref2.disableDroppedCart) != "true",
        cmsData: cmsRefinedData,
        redirectUrl: getRedirectUrlFromHomePage(basePathRedirect, placeId || "", offerId || 0, cmsId || "", (cookieCart === null || cookieCart === void 0 ? void 0 : cookieCart.hubPath) || "", isLockinProduct),
        onTrack: onTrack
    };
};
export default useHeroSliderConsumer;
