import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 35px 20px 37px;\n  text-align: left;\n  font-weight: 400;\n  color: ",
        ";\n  font-family: ",
        ";\n\n  div {\n    justify-content: left;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 59px 40px 0;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 60px 55px 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  ",
        "\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  font-weight: 700;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  div {\n    height: ",
        ";\n  }\n\n  span {\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin-bottom: ",
        ";\n  margin-top: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: ",
        ";\n    margin-top: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: inherit;\n  background: linear-gradient(0.25turn, transparent, ",
        ", transparent),\n    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);\n  background-repeat: no-repeat;\n  animation: loading 1.5s infinite;\n\n  :first-child {\n    margin-top: 60px;\n    margin-bottom: 16px;\n    height: 45px;\n  }\n\n  height: 150px;\n  margin-top: 32px;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n\n  @keyframes loading {\n    to {\n      background-position: 315px 0, 0 0, 0 190px, 50px 195px;\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-3ffb9fab-0"
})(_templateObject(), colors.$262626, fonts.regular, breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-3ffb9fab-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(30, 38), pxToRem(16), breakpoints.tablet, pxToCssFont(42, 52), breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-3ffb9fab-2"
})(_templateObject2(), pxToCssFont(20, 26), pxToRem(32), breakpoints.tablet, pxToCssFont(20, 30), breakpoints.desktop, pxToRem(40));
export var Lable = styled.div.withConfig({
    componentId: "sc-3ffb9fab-3"
})(_templateObject3(), pxToCssFont(18, 24), breakpoints.tablet, pxToCssFont(20, 30), pxToRem(24), colors.$00697c);
export var BottomText = styled.div.withConfig({
    componentId: "sc-3ffb9fab-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(20, 26), pxToRem(16), pxToRem(32), breakpoints.tablet, pxToCssFont(24, 30), pxToRem(12), pxToRem(40));
export var TextLoader = styled.div.withConfig({
    componentId: "sc-3ffb9fab-5"
})(_templateObject5(), colors.$ffffff, breakpoints.tablet);
