import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/codebuild/output/src3860616924/src/web-cloud-prod-vf-it/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * This method calls the locateStoreService.
 * This methods is able to find the store related to the address.
 * Method: POST
 * @param payload
 * @returns
 */ var locateStoreService = function(payload) {
    return nextClient.post("".concat(API.CARE_V1, "/").concat(API.LOCATE_STORE), payload);
};
/**
 * Custom mutation hook for run locateStoreService using the addressID.
 * @returns
 */ export var useLocateStore = function() {
    var queryClient = useQueryClient();
    var keyString = "locateStore";
    return useMutation([
        "locateStoreMutation"
    ], function(id) {
        var ref, ref1, ref2;
        var installationAddress = getFromLocalStorageByKey("createdAddress");
        var customerData = getFromLocalStorageByKey("customerData");
        var contactMedium = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.contactMedium) || [];
        var postalAddress = contactMedium === null || contactMedium === void 0 ? void 0 : contactMedium.find(function(contact) {
            return contact.type == "postalAddress";
        });
        var addressId = ((ref2 = installationAddress) === null || ref2 === void 0 ? void 0 : ref2.id) || (postalAddress === null || postalAddress === void 0 ? void 0 : postalAddress.id);
        if (id) {
            addressId = id;
        }
        var payload = {
            addressId: addressId,
            geoLocations: {},
            radius: 30,
            isPickUpStore: "Y",
            checkInventoryAvailability: false
        };
        return locateStoreService(payload);
    }, {
        onMutate: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(data) {
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            if (data) {
                                keyString += data;
                            }
                        case 1:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(data) {
                return _ref.apply(this, arguments);
            };
        }(),
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
